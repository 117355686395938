import React from "react"
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

class Refugee extends React.Component {
    componentDidMount() {
        document.title = 'Women Empowerment - Arev Society';
    }
    render() {
        return (
            <>
                <>
                    <Helmet>
                        <title>Refugee Women - Arev Society</title>

                        <meta charset="utf-8" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <meta
                            name="description"
                            content="Explore how Arev Society is empowering women and fostering positive change. Learn about our initiatives and success stories in women empowerment." />
                        <meta
                            name="keywords"
                            content="women empowerment, gender equality, women's rights, Arev Society initiatives" />
                        <meta name="author" content="Arev Society" />
                        <meta name="format-detection" content="telephone=no" />

                        <link rel="canonical" href="https://www.arevsociety.org/women-empowerment"></link>
                    </Helmet>
                    <section
                        id="intro"
                        style={{ backgroundImage: "url(images/avs_background.webp)" }}
                    >
                        <div className="intro-content">
                            <h1>
                                <span>Refugee </span>Women
                            </h1>
                            <div />
                        </div>
                    </section>
                    {/* #intro */}
                    <main>
                    <div className="container-stories-page">
                    <div className="article-story-page">
                        <h1
                            className="title-story-page"
                            title="Supporting Women Refugees in Armenia "
                        >
                            <strong>Refugee Women Empowerment</strong>
                        </h1>
                        The Arev Society is proud to announce the successful completion of our impactful 2024 collaboration with the
                        <a href="https://af4sd.org" style={{ marginRight: '5px', marginLeft: '5px' }}>
                            Armenian Fund for Sustainable Development (AF4SD)
                        </a>.
                        Together, we supported refugee women from Artsakh through a transformative initiative focused on income-generating agricultural projects and vocational training.
                        <br />
                        <br />
                        Starting on February 1, 2024, the Arev Society provided essential management and reporting assistance to the AF4SD as they funded agricultural projects designed to empower vulnerable refugee women forcibly displaced by recent conflicts.
                        <br />
                        <br />
                        To launch this meaningful program, the Arev Society and the AF4SD hosted a series of training workshops in February, March, May, and October 2024. These workshops equipped participants with critical entrepreneurial skills, laying the foundation for sustainable livelihoods.
                        <br />
                        <br />
                        During intensive short sessions, beneficiaries learned how to create business plans, received coaching on accounting basics, and gained the tools needed to transform their ideas into viable businesses. After the workshops, participants submitted their business proposals to the AF4SD for funding consideration.
                        <br />
                        <br />
                        A joint Funding Committee, comprising members of the AF4SD Board and the Arev Society’s Director, carefully evaluated the proposals. The most promising projects were awarded grants in the form of in-kind contributions. Before funding approval, the project team conducted thorough assessments at each business location to ensure feasibility and long-term success.
                        <br />
                        <br />
                        By combining training, funding, and mentorship, this program empowered refugee women to achieve financial independence and stability through entrepreneurship. The supported projects spanned both agricultural and production sectors. Additionally, some beneficiaries received funding for vocational training outside agriculture, tailored to enhance their competitiveness in the job market and support financial self-sufficiency.
                        <br />
                        <br />
                        In 2024, the program supported <strong>35 small businesses</strong> in agriculture and production and <strong>38 vocational training programs</strong>.
                        <br />
                        <br />
                        These results demonstrate our unwavering commitment to creating better opportunities for refugee women. We are immensely proud of this progress and remain dedicated to expanding our efforts in the future.
                        <p className="back-link">
                            <a href="women-empowerment-stories">
                                Back to Women empowerment stories
                            </a>
                        </p>
                    </div>
                </div>


                    </main>
                </>
            </>
        )
    }
}

export default Refugee